import React, { useState, useEffect } from "react";
import axiosInstance from "../services/api";

const Channels = () => {
  const [channels, setChannels] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    password: "",
    permissions: [],
    quota: 1000,
    status: "Offline"
  });
  const [editChannelId, setEditChannelId] = useState(null);

  const permissionsList = ["Access Pricing Engine", "Access Analytics", "Submit Trade-Ins"];

  // Fetch channels on load
  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await axiosInstance.get("/api/channels");
        setChannels(response.data);
      } catch (err) {
        console.error("Error fetching channels:", err);
      }
    };

    fetchChannels();
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle permission toggle
  const handlePermissionToggle = (permission) => {
    setFormData((prev) => {
      const newPermissions = prev.permissions.includes(permission)
        ? prev.permissions.filter((perm) => perm !== permission)
        : [...prev.permissions, permission];
      return { ...prev, permissions: newPermissions };
    });
  };

  // Add a new channel
  const handleAddChannel = async () => {
    try {
      const response = await axiosInstance.post("/api/channels", formData);
      setChannels([...channels, response.data]);
      setFormData({ name: "", password: "", permissions: [], quota: 1000, status: "Offline" });
    } catch (err) {
      console.error("Error adding channel:", err);
    }
  };

  // Edit an existing channel
  const handleEditChannel = async () => {
    try {
      const response = await axiosInstance.put(`/api/channels/${editChannelId}`, formData);

      setChannels(
        channels.map((channel) =>
          channel._id === editChannelId ? response.data : channel
        )
      );
      setEditChannelId(null);
      setFormData({ name: "", password: "", permissions: [], quota: 1000, status: "Offline" });
    } catch (err) {
      console.error("Error editing channel:", err);
    }
  };

  // Delete a channel
  const handleDeleteChannel = async (id) => {
    try {
      await axiosInstance.delete(`/api/channels/${id}`);
      setChannels(channels.filter((channel) => channel._id !== id));
    } catch (err) {
      console.error("Error deleting channel:", err);
    }
  };

  // Pre-fill the form for editing
  const handleEditClick = (channel) => {
    setEditChannelId(channel._id);
    setFormData({
      name: channel.name,
      password: "",
      permissions: channel.permissions,
      quota: channel.quota,
      status: channel.status
    });
  };

  const handleToggleStatus = async (id, currentStatus) => {
    try {
      const updatedStatus = currentStatus === "Online" ? "Offline" : "Online";
      const response = await axiosInstance.put(`/api/channels/${id}`, { status: updatedStatus });

      setChannels(
        channels.map((channel) => 
          channel._id === id ? { ...channel, status: response.data.status } : channel)
      );
    } catch (err) {
      console.error("Error toggling channel status:", err);
    }
  }

  return (
    <div className="text-gray-700">
      <h2 className="text-2xl font-bold mb-4">Channels Management</h2>

      {/* Form for Adding/Editing Channels */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <div className="grid grid-cols-1 gap-4">
          <label className="block">
            <span className="text-gray-700">Channel Name</span>
            <input
              type="text"
              name="name"
              placeholder="Channel Name"
              value={formData.name}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>

          <label className="block">
            <span className="text-gray-700">Password</span>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>

          <label className="block">
            <span className="text-gray-700">Quota</span>
            <input
              type="number"
              name="quota"
              placeholder="Quota"
              value={formData.quota}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>

          <label className="block">
            <span className="text-gray-700">Status</span>
            <select
              name="status"
              value={formData.status}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              >
              <option value="Online">Online</option>
              <option value="Offline">Offline</option>
              </select>
          </label>

          <label className="block">
            <span className="text-gray-700">Permissions</span>
            <div className="mt-2 space-y-2">
              {permissionsList.map((permission) => (
                <label key={permission} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={formData.permissions.includes(permission)}
                    onChange={() => handlePermissionToggle(permission)}
                    className="form-checkbox"
                  />
                  <span>{permission}</span>
                </label>
              ))}
            </div>
          </label>

          <button
            onClick={editChannelId ? handleEditChannel : handleAddChannel}
            className={`${
              editChannelId ? "bg-green-500" : "bg-blue-500"
            } text-white px-4 py-2 rounded shadow`}
          >
            {editChannelId ? "Update Channel" : "Add Channel"}
          </button>
        </div>
      </div>

      {/* Channels Table */}
      <table className="w-full bg-white shadow-md rounded-lg">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-2">Name</th>
            <th className="p-2">API Key</th>
            <th className="p-2">Permissions</th>
            <th className="p-2">Quota</th>
            <th className="p-2">Status</th>
            <th className="p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {channels.map((channel) => (
            <tr key={channel._id} className="border-t">
              <td className="p-2">{channel.name}</td>
              <td className="p-2">{channel.apiKey}</td>
              <td className="p-2">{channel.permissions.join(", ")}</td>
              <td className="p-2">
                {channel.quotaUsed || 0} / {channel.quota}
              </td>
              <td className="p-2">
                <span className={`px-2 py-1 rounded-full text-xs ${channel.status === "Online"
                    ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"}`}>
                  {channel.status}
                </span>
              </td>
              <td className="p-2 space-x-2">
                <button
                  onClick={() => handleEditClick(channel)}
                  className="bg-yellow-500 text-white px-2 py-1 rounded"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleToggleStatus(channel._id, channel.status)}
                  className="bg-blue-500 text-white px-2 py-1 rounded"
                >
                  Toggle Status
                </button>
                <button
                  onClick={() => handleDeleteChannel(channel._id)}
                  className="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Channels;
