import React from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineDashboard, AiOutlineTool, AiOutlineTeam } from "react-icons/ai";

const Sidebar = () => {
  const role = localStorage.getItem("token") ? "admin" : "channel";

  const adminNavItems = [
    { name: "Dashboard", icon: <AiOutlineDashboard />, path: "/" },
    { name: "Devices", icon: <AiOutlineTool />, path: "/devices" },
    { name: "Channels", icon: <AiOutlineTeam />, path: "/channels" },
    { name: "Deductions", icon: <AiOutlineTeam />, path: "/deductions" },
  ];

  const channelNavItems = [
    { name: "Dashboard", icon: <AiOutlineDashboard />, path: "/" },
  ];

  const navItems = role === "admin" ? adminNavItems : channelNavItems;

  return (
    <div className="h-screen bg-gray-800 text-white w-64 flex flex-col">
      <div className="p-4 text-lg font-bold">
        {role === "admin" ? "Admin Panel" : "Channel Panel"}
      </div>
      <nav className="flex flex-col space-y-2 p-4">
        {navItems.map((item, index) => (
          <NavLink
            key={index}
            to={item.path}
            className={({ isActive }) =>
              `flex items-center space-x-2 p-2 rounded-lg ${
                isActive ? "bg-gray-700" : "hover:bg-gray-700"
              }`
            }
          >
            {item.icon}
            <span>{item.name}</span>
          </NavLink>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;
