import React, { useState, useEffect } from "react";
import axiosInstance from "../services/api";

const ChannelDashboard = () => {
  const [channelData, setChannelData] = useState({
    name: "",
    apiKey: "",
    quota: 0,
    quotaUsed: 0,
  });
  const [submissions, setSubmissions] = useState([]);
  const channelId = localStorage.getItem("channelId");

  useEffect(() => {
    const fetchChannelData = async () => {
      try {
        const response = await axiosInstance.get(`/api/channels/${channelId}`);
        setChannelData(response.data);
      } catch (err) {
        console.error("Error fetching channel data:", err);
      }
    };

    const fetchSubmissions = async () => {
      try {
        const response = await axiosInstance.get(`/api/channel-submissions/${channelId}`);
        setSubmissions(response.data.submissions);
      } catch (err) {
        console.error("Error fetching submissions:", err);
      }
    };

    fetchChannelData();
    fetchSubmissions();
  }, [channelId]);

  return (
    <div className="text-gray-700">
      <h2 className="text-2xl font-bold mb-4">Channel Dashboard</h2>

      {/* Quota and API Key Section */}
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="bg-white shadow-md p-4 rounded-lg">
          <h3 className="text-lg font-semibold">Quota Usage</h3>
          <p className="text-2xl">
            {channelData.quotaUsed} / {channelData.quota}
          </p>
        </div>
        <div className="bg-white shadow-md p-4 rounded-lg">
          <h3 className="text-lg font-semibold">API Key</h3>
          <p className="text-sm font-mono bg-gray-100 p-2 rounded">
            {channelData.apiKey}
          </p>
        </div>
      </div>

      {/* Submissions Section */}
      <div className="bg-white shadow-md p-4 rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Submissions</h3>
        {submissions.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {submissions.map((submission) => (
              <li key={submission._id} className="py-2">
                <p><strong>Device:</strong> {submission.device}</p>
                <p><strong>Trade-In Value:</strong> Rs. {submission.tradeInValue}</p>
                <p><strong>Date:</strong> {new Date(submission.createdAt).toLocaleDateString()}</p>
                <p><strong>Options:</strong></p>
                <ul className="ml-4">
                  <li><strong>Storage:</strong> {submission.options.storageSize}</li>
                  <li><strong>Functional:</strong> {submission.options.functional}</li>
                  <li><strong>Repaired:</strong> {submission.options.repaired}</li>
                  <li><strong>Condition:</strong> {submission.options.condition}</li>
                  <li><strong>PTA Approved:</strong> {submission.options.ptaApproved}</li>
                  <li><strong>Accessories:</strong> {submission.options.accessories}</li>
                </ul>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">No submissions found.</p>
        )}
      </div>
    </div>
  );
};

export default ChannelDashboard;
