import React, { useState, useEffect } from "react";
import axiosInstance from "../services/api";

const Dashboard = () => {
    const [stats, setStats] = useState({
        totalDevices: 0,
        totalChannels: 0,
        totalSubmissions: 0,
    });
    const [channels, setChannels] = useState([]);

    useEffect(() => {
        const fetchStatsAndChannels = async () => {
            try {
                const devices = await axiosInstance.get("/api/devices");
                const channelsResponse = await axiosInstance.get("/api/channels");
                const submissions = channelsResponse.data.reduce(
                    (total, channel) => total + (channel.submissions || 0),
                    0
                );

                setStats({
                    totalDevices: devices.data.length,
                    totalChannels: channelsResponse.data.length,
                    totalSubmissions: submissions,
                });

                // Fetch channels for table
                setChannels(channelsResponse.data);
            } catch (err) {
                console.error("Error fetching stats or channels", err);
            }
        };

        fetchStatsAndChannels();
    }, []);

    return (
        <div className="text-gray-700">
            <h2 className="text-2xl font-bold mb-4">Dashboard</h2>

            {/* Stats Section */}
            <div className="grid grid-cols-3 gap-4 mb-6">
                <div className="bg-white shadow-md p-4 rounded-lg">
                    <h3 className="text-lg font-semibold">Total Devices</h3>
                    <p className="text-2xl">{stats.totalDevices}</p>
                </div>
                <div className="bg-white shadow-md p-4 rounded-lg">
                    <h3 className="text-lg font-semibold">Total Channels</h3>
                    <p className="text-2xl">{stats.totalChannels}</p>
                </div>
                <div className="bg-white shadow-md p-4 rounded-lg">
                    <h3 className="text-lg font-semibold">Total Submissions</h3>
                    <p className="text-2xl">{stats.totalSubmissions}</p>
                </div>
            </div>

            {/* Channels Table */}
            <div className="bg-white shadow-md rounded-lg p-6">
                <h3 className="text-xl font-bold mb-4">Channels Overview</h3>
                <table className="w-full bg-white shadow-md rounded-lg">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="p-2">API Key</th>
                            <th className="p-2">Name</th>
                            <th className="p-2">Status</th>
                            <th className="p-2">Submissions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {channels.map((channel) => (
                            <tr key={channel._id} className="border-t">
                                <td className="p-2">{channel.apiKey}</td>
                                <td className="p-2">{channel.name}</td>
                                <td className="p-2">
                                    <span
                                        className={`px-2 py-1 rounded text-white ${
                                            channel.status === "Online"
                                                ? "bg-green-500"
                                                : "bg-red-500"
                                        }`}
                                    >
                                        {channel.status || "Offline"}
                                    </span>
                                </td>
                                <td className="p-2">{channel.submissions || 0}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Dashboard;
