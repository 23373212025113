import React, { useState } from "react";
import axiosInstance from "../services/api";

const Login = () => {
    const [formData, setFormData] = useState({
        username: "",
        password: "",
        apiKey: "",
    });
    const [isChannelLoginStep2, setIsChannelLoginStep2] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleLogin = async () => {
        try {
            const adminResponse = await axiosInstance.post("auth/admin/login", {
                username: formData.username,
                password: formData.password,
            });
            if (adminResponse.data.role === "admin") {
                console.log("Admin login successful");
                localStorage.setItem("token", adminResponse.data.token);
                localStorage.setItem("username", adminResponse.data.username);
                window.location.href = "/admin";
                return;
            }
        } catch {
            try {
                const channelResponse = await axiosInstance.post("auth/channel/login/step1", {
                    username: formData.username,
                    password: formData.password,
                });

                if (channelResponse.data.role === "channel") {
                    setIsChannelLoginStep2(true);
                }
            } catch (err) {
                console.error("Login Error:", err);
                alert("Invalid credentials for admin or channel.");
            }
        }
    };

    const handleApiKeySubmit = async () => {
        try {
            const channelResponse = await axiosInstance.post("auth/channel/login/step2", {
                username: formData.username,
                apiKey: formData.apiKey,
            });

            console.log("Channel login successful");
            localStorage.setItem("channelId", channelResponse.data.channelId);
            localStorage.setItem("username", channelResponse.data.username);
            window.location.href = `/channel-dashboard`;
        } catch (err) {
            console.error("Login Error:", err);
            alert("Invalid API Key.");
        }
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
                <h2 className="text-xl font-bold mb-4">Login</h2>
                <div className="space-y-4">
                    <label className="block">
                        <span className="text-gray-700">Username</span>
                        <input
                            type="text"
                            name="username"
                            value={formData.username}
                            onChange={handleInputChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                        />
                    </label>
                    <label className="block">
                        <span className="text-gray-700">Password</span>
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                        />
                    </label>
                    {isChannelLoginStep2 && (
                        <label className="block">
                            <span className="text-gray-700">API Key</span>
                            <input
                                type="text"
                                name="apiKey"
                                value={formData.apiKey}
                                onChange={handleInputChange}
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                            />
                        </label>
                    )}
                    <button
                        onClick={isChannelLoginStep2 ? handleApiKeySubmit : handleLogin}
                        className="bg-blue-500 text-white px-4 py-2 rounded shadow w-full"
                    >
                        {isChannelLoginStep2 ? "Submit API Key" : "Login"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Login;