import React, { useState, useEffect } from "react";
import axiosInstance from "../services/api";

const Devices = () => {
  const [devices, setDevices] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    deviceType: "",
    vendor: "",
    basePrice: "",
  });

  const [deviceNames, setDeviceNames] = useState([]);
  const [vendors, setVendors] = useState([]);
  const deviceTypes = ["Phone", "Tablet", "Laptop"];

  const deviceNameOptions = {
  Phone: {
    Apple: [
      "iPhone 15 Pro Max",
      "iPhone 15 Pro",
      "iPhone 15 Plus",
      "iPhone 15",
      "iPhone 14 Pro Max",
      "iPhone 14 Pro",
      "iPhone 14 Plus",
      "iPhone 14",
      "iPhone SE (3rd Gen)",
      "iPhone 13 Pro Max",
      "iPhone 13 Pro",
      "iPhone 13",
      "iPhone 13 Mini",
      "iPhone 12 Pro Max",
      "iPhone 12 Pro",
      "iPhone 12",
      "iPhone 12 Mini",
      "iPhone SE (2nd Gen)",
      "iPhone 11 Pro Max",
      "iPhone 11 Pro",
      "iPhone 11",
      "iPhone XS Max",
      "iPhone XS",
      "iPhone XR",
      "iPhone X",
    ],
    Samsung: [
      "Galaxy S23 Ultra",
      "Galaxy S23+",
      "Galaxy S23",
      "Galaxy Z Fold 5",
      "Galaxy Z Flip 5",
      "Galaxy S22 Ultra",
      "Galaxy S22+",
      "Galaxy S22",
      "Galaxy S21 Ultra",
      "Galaxy S21+",
      "Galaxy S21",
      "Galaxy Note 20 Ultra",
      "Galaxy Note 20",
    ],
    Xiaomi: [
      "Xiaomi 13 Pro",
      "Xiaomi 13",
      "Xiaomi 12 Pro",
      "Xiaomi 12",
      "Xiaomi Mi 11 Ultra",
      "Xiaomi Mi 11",
      "Redmi Note 12 Pro",
      "Redmi Note 12",
      "Redmi Note 11 Pro",
      "Redmi Note 11",
    ],
    Vivo: [
      "Vivo X90 Pro",
      "Vivo X90",
      "Vivo X80 Pro",
      "Vivo X80",
      "Vivo V27 Pro",
      "Vivo V27",
      "Vivo Y100",
      "Vivo Y75",
      "Vivo Y21",
    ],
    Oppo: [
      "Oppo Find X6 Pro",
      "Oppo Find X6",
      "Oppo Reno10 Pro+",
      "Oppo Reno10 Pro",
      "Oppo Reno10",
      "Oppo Reno8 Pro",
      "Oppo Reno8",
      "Oppo A98",
      "Oppo A78",
    ],
    Google: [
      "Pixel 8 Pro",
      "Pixel 8",
      "Pixel 7 Pro",
      "Pixel 7",
      "Pixel 6 Pro",
      "Pixel 6",
    ],
  },
  Tablet: {
    Apple: [
      "iPad Pro 12.9-inch",
      "iPad Pro 11-inch",
      "iPad Air",
      "iPad Mini",
      "iPad 10th Gen",
    ],
    Samsung: [
      "Galaxy Tab S9 Ultra",
      "Galaxy Tab S9+",
      "Galaxy Tab S9",
      "Galaxy Tab S8 Ultra",
      "Galaxy Tab S8+",
      "Galaxy Tab S8",
    ],
    Microsoft: [
      "Surface Pro 9",
      "Surface Pro 8",
      "Surface Go 3",
    ],
  },
  Laptop: {
    Apple: [
      "MacBook Pro 16-inch",
      "MacBook Pro 14-inch",
      "MacBook Air M2",
      "MacBook Air M1",
    ],
    Dell: [
      "XPS 15",
      "XPS 13 Plus",
      "XPS 13",
      "Inspiron 16",
      "Inspiron 15",
      "Alienware m18",
      "Alienware x17 R2",
    ],
    HP: [
      "Spectre x360 16",
      "Spectre x360 14",
      "Pavilion Plus 14",
      "Pavilion x360 15",
      "Omen 16",
      "Envy x360 15",
    ],
    Lenovo: [
      "ThinkPad X1 Carbon",
      "ThinkPad X1 Yoga",
      "Yoga Slim 9i",
      "Legion Pro 7i",
      "IdeaPad Flex 5",
      "IdeaPad Gaming 3i",
    ],
    Asus: [
      "ROG Zephyrus G14",
      "ROG Strix Scar 16",
      "ZenBook Pro 14 Duo",
      "VivoBook S 15",
      "TUF Gaming A15",
    ],
  },
};

  // Fetch devices on load
  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axiosInstance.get("/api/devices");
        setDevices(response.data);
      } catch (err) {
        console.error("Error fetching devices", err);
      }
    };

    fetchDevices();
  }, []);

  const handleInputChange = (e) => {
  const { name, value } = e.target;

  if (name === "deviceType") {
    setFormData({ ...formData, [name]: value, vendor: "", name: "" });
    setVendors(Object.keys(deviceNameOptions[value] || {}));
    setDeviceNames([]);
  } else if (name === "vendor") {
    setFormData({ ...formData, [name]: value, name: "" });
    setDeviceNames(deviceNameOptions[formData.deviceType]?.[value] || []);
  } else {
    setFormData({ ...formData, [name]: value });
  }
};


  // Add a new device
  const handleAddDevice = async () => {
    try {
      const response = await axiosInstance.post("/api/devices", formData);
      setDevices([...devices, response.data]);
      setFormData({
        name: "",
        deviceType: "",
        vendor: "",
        basePrice: "",
      });
      setVendors([]);
      setDeviceNames([]);
    } catch (err) {
      console.error("Error adding device", err);
    }
  };

  // Delete a device
  const handleDeleteDevice = async (id) => {
    try {
      await axiosInstance.delete(`/api/devices/${id}`);
      setDevices(devices.filter((device) => device._id !== id));
    } catch (err) {
      console.error("Error deleting device", err);
    }
  };

  return (
    <div className="text-gray-700">
      <h2 className="text-2xl font-bold mb-6">Devices Management</h2>

      {/* Form */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <div className="grid grid-cols-1 gap-4">
          <label className="block">
            <span className="text-gray-700">Device Type</span>
            <select
              name="deviceType"
              value={formData.deviceType}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            >
              <option value="">Select Device Type</option>
              {deviceTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </label>

          <label className="block">
            <span className="text-gray-700">Vendor</span>
            <select
              name="vendor"
              value={formData.vendor}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              disabled={!vendors.length}
            >
              <option value="">Select Vendor</option>
              {vendors.map((vendor) => (
                <option key={vendor} value={vendor}>
                  {vendor}
                </option>
              ))}
            </select>
          </label>

          <label className="block">
            <span className="text-gray-700">Device Name</span>
            <select
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              disabled={!deviceNames.length}
            >
              <option value="">Select Device Name</option>
              {deviceNames.map((deviceName) => (
                <option key={deviceName} value={deviceName}>
                  {deviceName}
                </option>
              ))}
            </select>
          </label>

          <label className="block">
            <span className="text-gray-700">Base Price</span>
            <input
              type="number"
              name="basePrice"
              placeholder="Enter Base Price"
              value={formData.basePrice}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>

          <button
            onClick={handleAddDevice}
            className="bg-blue-500 text-white px-4 py-2 rounded shadow"
          >
            Add Device
          </button>
        </div>
      </div>

      {/* Devices Table */}
      <table className="w-full bg-white shadow-md rounded-lg">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-2">Name</th>
            <th className="p-2">Device Type</th>
            <th className="p-2">Vendor</th>
            <th className="p-2">Base Price</th>
            <th className="p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {devices.map((device) => (
            <tr key={device._id} className="border-t">
              <td className="p-2">{device.name}</td>
              <td className="p-2">{device.deviceType}</td>
              <td className="p-2">{device.vendor}</td>
              <td className="p-2">PKR {device.basePrice.toLocaleString()}</td>
              <td className="p-2">
                <button
                  onClick={() => handleDeleteDevice(device._id)}
                  className="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Devices;
