import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Dashboard from "./pages/Dashboard";
import Devices from "./pages/Devices";
import Channels from "./pages/Channels";
import Deductions from "./pages/Deductions";
import Login from "./pages/Login";
import ChannelDashboard from "./pages/Channels-Dashboard";

// Role-based Access Control
const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  const channelId = localStorage.getItem("channelId");
  return token || channelId;
};

const getRole = () => {
  return localStorage.getItem("token") ? "admin" : "channel";
}

const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }
  return children;
}

const App = () => {
  return (
    <Router>
      <div className="flex">
        {isAuthenticated() && <Sidebar />}
        <div className="flex-1">
          {isAuthenticated() && <Header />}
          <main className="p-4">
            <Routes>
              {/* Public Route */}
              <Route path="/login" element={<Login />} />

              {/* Protected Routes */}
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    {getRole() === "admin" ? <Dashboard /> : <Navigate to="/channel-dashboard" />}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/devices"
                element={
                  <ProtectedRoute>
                    {getRole() === "admin" ? <Devices /> : <Navigate to="/" />}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/channels"
                element={
                  <ProtectedRoute>
                    {getRole() === "admin" ? <Channels /> : <Navigate to="/" />}
                  </ProtectedRoute>
                }
              />
              <Route
                path="/deductions"
                element={
                  <ProtectedRoute>
                    {getRole() === "admin" ? <Deductions /> : <Navigate to="/" />}
                  </ProtectedRoute>
                }
              />

              {/* Channel-Specific Admin Panel */}
              <Route
                path="/channel-dashboard"
                element={
                  <ProtectedRoute>
                    {getRole() === "channel" ? <ChannelDashboard /> : <Navigate to="/" />}
                  </ProtectedRoute>
                }
              />
            </Routes>
          </main>
        </div>
      </div>
    </Router>
  );
};


export default App;
