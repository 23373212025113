import React, { useState, useEffect } from "react";
import axiosInstance from "../services/api";

const Deductions = () => {
  const [devices, setDevices] = useState([]);
  const [channels, setChannels] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const [storageOptions, setStorageOptions] = useState([]);

  const [formData, setFormData] = useState({
    deviceId: "",
    channelId: "",
    isFunctional: 0,
    isRepaired: 0,
    conditionGrading: { Excellent: 0, Good: 0, Fair: 0 },
    ptaApproved: 0,
    accessories: 0,
  });

  const [selectedDevice, setSelectedDevice] = useState("");
  const [selectedChannel, setSelectedChannel] = useState("");
  const [storageForm, setStorageForm] = useState({ variant: "", deduction: 0 });

  // Fetch devices and channels on load
  useEffect(() => {
    const fetchData = async () => {
      try {
        const devicesResponse = await axiosInstance.get("/api/devices");
        const channelsResponse = await axiosInstance.get("/api/channels");

        setDevices(devicesResponse.data);
        setChannels(channelsResponse.data);
      } catch (err) {
        console.error("Error fetching devices or channels:", err);
      }
    };

    fetchData();
  }, []);

  // Fetch deductions when device and channel are selected
  useEffect(() => {
    const fetchDeductions = async () => {
      if (selectedDevice && selectedChannel) {
        try {
          const response = await axiosInstance.get(
            `/api/deductions?deviceId=${selectedDevice}&channelId=${selectedChannel}`
          );
          setDeductions(response.data);
        } catch (err) {
          console.error("Error fetching deductions:", err);
        }
      }
    };

    fetchDeductions();
  }, [selectedDevice, selectedChannel]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name in formData.conditionGrading) {
      setFormData({
        ...formData,
        conditionGrading: { ...formData.conditionGrading, [name]: Number(value) },
      });
    } else {
      setFormData({ ...formData, [name]: Number(value) });
    }
  };

  // Handle storage form changes
  const handleStorageInputChange = (e) => {
    const { name, value } = e.target;
    setStorageForm({ ...storageForm, [name]: value });
  };

  // Add storage option to list
  const handleAddStorageOption = () => {
    if (storageForm.variant && storageForm.deduction >= 0) {
      setStorageOptions([...storageOptions, { ...storageForm }]);
      setStorageForm({ variant: "", deduction: 0 });
    }
  };

  const handleRemoveStorageOption = (index) => {
    const updatedStorageOptions = [...storageOptions];
    updatedStorageOptions.splice(index, 1);
    setStorageOptions(updatedStorageOptions);
  };

  // Submit all deductions to backend
  const handleAddDeduction = async () => {
    try {
      const response = await axiosInstance.post("/api/deductions", {
        ...formData,
        deviceId: selectedDevice,
        channelId: selectedChannel,
        storageOptions,
      });

      setDeductions([...deductions, response.data]);
      setFormData({
        deviceId: "",
        channelId: "",
        isFunctional: 0,
        isRepaired: 0,
        conditionGrading: { Excellent: 0, Good: 0, Fair: 0 },
        ptaApproved: 0,
        accessories: 0,
      });
      setStorageOptions([]);
    } catch (err) {
      console.error("Error adding deduction:", err);
    }
  };

  // Delete a deduction
  const handleDeleteDeduction = async (id) => {
    try {
      await axiosInstance.delete(`/api/deductions/${id}`);
      setDeductions(deductions.filter((deduction) => deduction._id !== id));
    } catch (err) {
      console.error("Error deleting deduction:", err);
    }
  };

  return (
    <div className="text-gray-700">
      <h2 className="text-2xl font-bold mb-4">Deductions Management</h2>

      {/* Dropdowns for Device and Channel Selection */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <div className="grid grid-cols-1 gap-4">
          <label className="block">
            <span className="text-gray-700">Select Device</span>
            <select
              value={selectedDevice}
              onChange={(e) => setSelectedDevice(e.target.value)}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            >
              <option value="">Select Device</option>
              {devices.map((device) => (
                <option key={device._id} value={device._id}>
                  {device.name}
                </option>
              ))}
            </select>
          </label>

          <label className="block">
            <span className="text-gray-700">Select Channel</span>
            <select
              value={selectedChannel}
              onChange={(e) => setSelectedChannel(e.target.value)}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            >
              <option value="">Select Channel</option>
              {channels.map((channel) => (
                <option key={channel._id} value={channel._id}>
                  {channel.name}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>

      {/* Form for General Deductions */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h3 className="text-xl font-bold mb-4">General Deductions</h3>
        <div className="grid grid-cols-2 gap-4">
          <label className="block">
            <span className="text-gray-700">Is Functional (%)</span>
            <input
              type="number"
              name="isFunctional"
              value={formData.isFunctional}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>

          <label className="block">
            <span className="text-gray-700">Is Repaired (%)</span>
            <input
              type="number"
              name="isRepaired"
              value={formData.isRepaired}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>

          <label className="block">
            <span className="text-gray-700">Condition Excellent (%)</span>
            <input
              type="number"
              name="Excellent"
              value={formData.conditionGrading.Excellent}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>

            <label className="block">
            <span className="text-gray-700">Condition Good (%)</span>
            <input
              type="number"
              name="Good"
              value={formData.conditionGrading.Good}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>

            <label className="block">
            <span className="text-gray-700">Condition Fair (%)</span>
            <input
              type="number"
              name="Fair"
              value={formData.conditionGrading.Fair}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>

          <label className="block">
            <span className="text-gray-700">PTA Approved (%)</span>
            <input
              type="number"
              name="ptaApproved"
              value={formData.ptaApproved}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>

          <label className="block">
            <span className="text-gray-700">Accessories (%)</span>
            <input
              type="number"
              name="accessories"
              value={formData.accessories}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>
        </div>
      </div>

      {/* Form for Adding Storage Options */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h3 className="text-xl font-bold mb-4">Storage Options</h3>
        <div className="grid grid-cols-2 gap-4">
          <label className="block">
            <span className="text-gray-700">Variant</span>
            <input
              type="text"
              name="variant"
              value={storageForm.variant}
              onChange={handleStorageInputChange}
              placeholder="e.g., 64GB"
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>
          <label className="block">
            <span className="text-gray-700">Deduction (%)</span>
            <input
              type="number"
              name="deduction"
              value={storageForm.deduction}
              onChange={handleStorageInputChange}
              placeholder="e.g., 10%"
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
          </label>
        </div>
        <button
          onClick={handleAddStorageOption}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded shadow"
        >
          Add Storage Option
        </button>

        {/* Display Added Storage Options */}
        {storageOptions.length > 0 && (
          <div className="mt-4">
            <h4 className="text-lg font-semibold mb-2">Added Storage Options</h4>
            <ul className="list-disc list-inside">
              {storageOptions.map((option, index) => (
                <li key={index} className="flex justify-between items-center">
                  <span>{`${option.variant} - Deduction: ${option.deduction}%`}</span>
                  <button
                    onClick={() => handleRemoveStorageOption(index)}
                    className="text-red-500"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Save All Deductions */}
      <button
        onClick={handleAddDeduction}
        className="bg-green-500 text-white px-4 py-2 rounded shadow"
      >
        Save Deductions
      </button>

      {/* Deductions Table */}
      <table className="w-full bg-white shadow-md rounded-lg mt-6">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-2">Storage Options</th>
            <th className="p-2">Is Functional</th>
            <th className="p-2">Is Repaired</th>
            <th className="p-2">Condition (Excellent, Good, Fair)</th>
            <th className="p-2">PTA Approved</th>
            <th className="p-2">Accessories</th>
            <th className="p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {deductions.map((deduction) => (
            <tr key={deduction._id} className="border-t">
              <td className="p-2">
                {deduction.storageOptions
                  .map((option) => `${option.variant} - ${option.deduction}%`)
                  .join(", ")}
              </td>
              <td className="p-2">{deduction.isFunctional}%</td>
              <td className="p-2">{deduction.isRepaired}%</td>
              <td className="p-2">
                {deduction.conditionGrading.Excellent}% / {deduction.conditionGrading.Good}% /{" "}
                {deduction.conditionGrading.Fair}%
              </td>
              <td className="p-2">{deduction.ptaApproved}%</td>
              <td className="p-2">{deduction.accessories}%</td>
              <td className="p-2">
                <button
                  onClick={() => handleDeleteDeduction(deduction._id)}
                  className="bg-red-500 text-white px-2 py-1 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Deductions;
