import React from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  // Retrieve user info
  const username = localStorage.getItem("username"); // Set during login
  const role = localStorage.getItem("token") ? "Admin" : "Channel";

  // Logout handler
  const handleLogout = () => {
    localStorage.clear(); // Clear all stored credentials
    navigate("/login"); // Redirect to login page
  };

  return (
    <header className="bg-gray-900 text-white p-4 flex justify-between items-center">
      <h1 className="text-xl font-bold">{role} Panel</h1>
      <div className="flex items-center space-x-4">
        <span className="text-sm font-semibold">
          Welcome, {username || "User"}
        </span>
        <button
          onClick={handleLogout}
          className="px-4 py-2 bg-red-500 rounded-lg"
        >
          Logout
        </button>
      </div>
    </header>
  );
};

export default Header;
